import React from 'react'
import Prism from 'prismjs'
import styled from 'styled-components'

const Block = styled.div`
  width: 100%;
`

export default ({ label, lang, code }) => {
  const html = Prism.highlight(code, Prism.languages[lang])

  return (
    <Block className="gatsby-highlight" data-language={label}>
      <pre dangerouslySetInnerHTML={{ __html: html }} />
    </Block>
  )
}

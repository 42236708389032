import React from 'react'
import Project from 'components/project'
import CodeBlock from 'components/code-block'
import ExternalLink from 'components/external-link'
import polysizeExample from 'lib/code-blocks/polysize'

export default () => (
  <Project name="Polysize">
    <div>
      <p>
        Polysize is a JavaScript class for resizing images using HTML5 Canvas.
      </p>
      <p>
        I developed Polysize and Polysize-Node when working for a client that received a lot of images from users. We wanted to resize images before they were uploaded to improve real and perceived performance. The code was open-sourced and is now free to adopt for your own projects.
      </p>
      <p>
        This project is no longer actively maintained but is still a useful tool.
      </p>
    </div>
    <div>
      <h2>Example Usage</h2>
      <CodeBlock label="example.js" lang="javascript" code={polysizeExample} />
      <p>
        <ExternalLink href="https://github.com/dw2/polysize#parameters">
        # View All Parameters
        </ExternalLink>
      </p>
    </div>
  </Project>
)

export default
`const handleFileInputChange = event => {
  const file = event.target.files[0];

  // Create a 150px avatar from the given file
  new Polysize(file, 150, img => {
    // Do something with img
    console.log('150px image element >>', img);
  });
};`
